export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'trading-networks/edit/'
  },
  {
    text: 'Сеть',
    value: 'name'
  },
  { text: 'Менеджер', value: 'manager' }
]
